export * from "./route"

export const AUTH_TOKEN_STORE_KEY = "auth_token"
export const CHANGE_PASSWORD_STORE_KEY = "change_password"

export const API_BASE_URL = "https://dev.cloud.1change.co"

export const LANGUAGES = {
  "en-US": "en-US",
  "zh-HK": "zh-HK",
  "cs-CS": "cs-CS",
  "nl-NL": "nl-NL",
  "fr-FR": "fr-FR",
  "de-DE": "de-DE",
  "hu-HU": "hu-HU",
  "it-IT": "it-IT",
  "ja-JA": "ja-JA",
  "ko-KO": "ko-KO",
  "pl-PL": "pl-PL",
  "pt-PT": "pt-PT",
  "es-ES": "es-ES",
  "th-TH": "th-TH",
}

export const LANGUAGES_FLAG = {
  "en-US": "🇺🇸 English",
  "zh-HK": "🇨🇳 Chinese",
  "cs-CS": "🇨🇿 Czech",
  "nl-NL": "🇳🇱 Dutch",
  "fr-FR": "🇫🇷 French",
  "de-DE": "🇩🇪 German",
  "hu-HU": "🇭🇺 Hungarian",
  "it-IT": "🇮🇹 Italian",
  "ja-JA": "🇯🇵 Japanese",
  "ko-KO": "🇰🇷 Korean",
  "pl-PL": "🇵🇱 Polish",
  "pt-PT": "🇵🇹 Portuguese",
  "es-ES": "🇪🇸 Spanish",
  "th-TH": "🇹🇭 Thai",
}

export const SCHEMA_LANGUAGES = {
  "en-US": "en_US",
  "zh-HK": "zh_HK",
  "cs-CS": "cs_CS",
  "nl-NL": "nl_NL",
  "fr-FR": "fr_FR",
  "de-DE": "de_DE",
  "hu-HU": "hu_HU",
  "it-IT": "it_IT",
  "ja-JA": "ja_JA",
  "ko-KO": "ko_KO",
  "pl-PL": "pl_PL",
  "pt-PT": "pt_PT",
  "es-ES": "es_ES",
  "th-TH": "th_TH",
}

export const FEED_FILTER = {
  COUNTRY: "COUNTRY",
  GLOBAL: "GLOBAL",
  TEAM: "TEAM",
  BUSINESS_UNIT: "UNIT",
  SITES: "SITES",
}

export const FEEDS_FILTER_OPTIONS = [
  {
    translationKey: "feed.filter_global",
    value: FEED_FILTER.GLOBAL,
    bgColor: "#f68f1f",
    boxShadow: "0 0 0 4px #ab5c07",
  },
  {
    translationKey: "feed.filter_country",
    value: FEED_FILTER.COUNTRY,
    bgColor: "#107c7c",
    boxShadow: "0 0 0 4px #094444",
  },
  {
    translationKey: "feed.filter_business_unit",
    value: FEED_FILTER.BUSINESS_UNIT,
    bgColor: "#862578",
    boxShadow: "0 0 0 4px #501647",
  },
  {
    translationKey: "feed.filter_sites",
    value: FEED_FILTER.SITES,
    bgColor: "#118ab2",
    boxShadow: "0 0 0 4px #0b5a75",
  },
  {
    translationKey: "feed.filter_team",
    value: FEED_FILTER.TEAM,
    bgColor: "#c00c1c",
    boxShadow: "0 0 0 4px #60060e",
  },
]

export const SCOREBOARD_FILTER = {
  COUNTRY: "COUNTRY",
  BUSINESS_UNIT: "UNIT",
  TEAM: "TEAM",
  GLOBAL: "GLOBAL",
  SITES: "SITES",
}

export const SCOREBOARD_FILTER_OPTIONS = [
  {
    translationKey: "feed.filter_country",
    value: SCOREBOARD_FILTER.COUNTRY,
    bgColor: "#107c7c",
    boxShadow: "0 0 0 4px #094444",
  },
  {
    translationKey: "feed.filter_business_unit",
    value: SCOREBOARD_FILTER.BUSINESS_UNIT,
    bgColor: "#862578",
    boxShadow: "0 0 0 4px #501647",
  },
  {
    translationKey: "feed.filter_sites",
    value: SCOREBOARD_FILTER.SITES,
    bgColor: "#118ab2",
    boxShadow: "0 0 0 4px #0b5a75",
  },
]

export const GENERAL_DROPDOWN_OPTION = {
  title: "GENERAL",
  title_cs: "OBECNÉ",
  title_de: "ALLGEMEIN",
  title_es: "GENERAL",
  title_fr: "GÉNÉRAL",
  title_hk: "整体",
  title_hu: "ÁLTALÁNOS",
  title_it: "GENERALE",
  title_ja: "一般",
  title_ko: "일반",
  title_nl: "ALGEMEEN",
  title_pl: "OGÓLNE",
  title_pt: "GERAL",
  title_th: "ทั่วไป",
  contentful_id: "",
  activities: [],
  slug: "general",
}

export const COMPANY_ID = "3W9bg48IpBNLf5ahFB1U46"

export const slugOrder = [
  "let-the-games-begin",
  "inclusion",
  "team-leadership-w1",
  "wellbeing-w1",
  "collaboration-w2",
  "team-leadership-w2",
  "wellbeing-w2",
  "empowerment-w3",
  "wellbeing-w3",
  "final-fun-challenge-w3",
]

export const allSlugs = [
  "empowerment-w3",
  "empowerment-1",
  "empowerment-1-option-1-bonus",
  "empowerment-option-2",
  "empowerment-option-2-bonus",
  "empowerment-option-3",
  "empowerment-option-3-bonus",
  "wellbeing-w3",
  "wellbeing-4",
  "final-fun-challenge-w3",
  "final-fun-challenge",
  "collaboration-w2",
  "collaboration-1",
  "collaboration-2-bonus-1",
  "collaboration-3",
  "team-leadership-w2",
  "team-leader-2",
  "wellbeing-w2",
  "wellbeing-3",
  "inclusion",
  "inclusion-1",
  "inclusion-2",
  "inclusion-3",
  "team-leadership-w1",
  "team-leader-1",
  "wellbeing-w1",
  "wellbeing-1",
  "wellbeing-2",
  "let-the-games-begin",
  "let-the-games-begin",
  "let-the-games-begin-bonus-1",
  "let-the-games-begin-bonus-2",
]

// for scoreboard page
export const MAP_TYPE = {
  [SCOREBOARD_FILTER.COUNTRY]: "country",
  [SCOREBOARD_FILTER.SITES]: "sites",
  [SCOREBOARD_FILTER.BUSINESS_UNIT]: "businessUnit",
}
